import React from "react";
import { useNavigate } from "react-router-dom";

export const Table = ({header, rows, loading}) => {
    const navigate = useNavigate();

    const handleOnClick = row => {
        if(row.action){
            row.action();
            return;
        }

        if(row.to){
            navigate(row.to);
            return;
        }
    }

    return (
        <table className="table">
            <thead>
                <tr>
                    {header.map((item, index) => (
                        <th key={index}>{item}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {!loading && rows.map((row, index) => (
                    <tr key={index} onClick={() => handleOnClick(row)} className={row.to || row.action? 'has-link' : ''}>
                        {row.data.map((item, index) => (
                            <td key={index}>{item}</td>
                        ))}
                    </tr>
                ))}
                {!loading && rows.length === 0 &&
                    <tr>
                        <td colSpan={header.length} className="no-data">Nenhum resultado encontrado</td>
                    </tr>
                }
                {loading &&
                    <tr>
                        <td colSpan={header.length} className="no-data">Carregando...</td>
                    </tr>
                }
                
            </tbody>
        </table>
    );
}

export default Table;