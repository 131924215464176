import React, { useEffect, useState } from "react";
import Select from 'react-select';

import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import FilterSimple from "../../components/FilterSimple";
import DotStatus from "../../components/DotStatus";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import moment from "moment";
import InputSelect from "../../components/InputSelect";
import Input from "../../components/Input";
import { createOptions } from "../../helpers/selects";
import { toast } from "react-toastify";
import ModalDelete from "../../components/ModalDelete";
import InputTextarea from "../../components/InputTextarea";
import InputFile from "../../components/InputFile";
import InputCheckbox from "../../components/InputCheckbox";
import PlanImagesEdit from "./Images/edit";
import TableSortable from "../../components/TableSortable";

const statusOptions = [
    { label: 'Ativo', value: 1 },
    { label: 'Inativo', value: 0 }
];

export const PlanList = () => {
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState('');
    const [data, setData] = useState({header: [], rows: []});

    const [plantas, setPlantas] = useState([]);
    const [tipos, setTipos] = useState([]);
    const [projetos, setProjetos] = useState([]);

    const [showCreatePlanta, setShowCreatePlanta] = useState(false);
    const [showUpdatePlanta, setShowUpdatePlanta] = useState(false);

    const [planta, setPlanta] = useState(null);
    const [plantaProjeto, setPlantaProjeto] = useState(0);
    const [plantaTipo, setPlantaTipo] = useState(0);
    const [plantaMetragem, setPlantaMetragem] = useState(null);
    const [plantaAlugar, setPlantaAlugar] = useState(0);
    const [plantaComprar, setPlantaComprar] = useState(0);
    const [plantaDescricao, setPlantaDescricao] = useState('');

    const [plantaDetalhes1, setPlantaDetalhes1] = useState('');
    const [plantaDetalhes2, setPlantaDetalhes2] = useState('');
    const [plantaDetalhes3, setPlantaDetalhes3] = useState('');

    const [plantaStatus, setPlantaStatus] = useState(1);
    const [plantaCvId, setPlantaCvId] = useState('');

    const [plantaImagemApresentacaoBase64, setPlantaImagemApresentacaoBase64] = useState('');
    const [plantaImagemApresentacaoName, setPlantaImagemApresentacaoName] = useState('');

    const [plantaImagemPlantaBase64, setPlantaImagemPlantaBase64] = useState('');
    const [plantaImagemPlantaName, setPlantaImagemPlantaName] = useState('');

    const [plantaToDelete, setPlantaToDelete] = useState(null);

    useEffect(() => {
        getPlantas();
        getProjetos();
        getTipos();
    }, []);

    useEffect(() => {
        if(filters) getPlantas();
    }, [filters]);

    useEffect(() => {
        if(!showUpdatePlanta) clean();
    }, [showUpdatePlanta]);

    useEffect(() => {
        if(!showCreatePlanta) clean();
    }, [showCreatePlanta]);

    useEffect(() => {
        generateData();
    }, [plantas]);

    const getProjetos = () => {
        api.get(`/projetos`).then(res => {
            setProjetos(res.data.data);
        });
    }

    const getTipos = () => {
        api.get(`/tipos`).then(res => {
            setTipos(res.data.data);
        });
    }

    const getPlantas = () => {
        setPlantas([]);
        setLoading(true);

        api.get(`/plantas${filters}`).then(res => {
            setPlantas(res.data.data);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const sort = items => {
        api.put(`/plantas/sort`, { plantas: items }).then(res => {
            console.log(res);
        }).catch(error => {
            renderError(error);
        });
    }

    const generateData = () => {
        const header = ['Imagem de apresentação', 'Projeto',  'Tipo de planta', 'Descrição', 'Disponibilidade', 'Metragem', 'Status'];
        const rows = [];

        plantas.map(planta => {
            const imagemApresentacao = planta.imagens.length > 0 ? <img src={planta.imagens[0].path} /> : '';
            const arrDisponibilidade = [];
            if(planta.comprar === 1) arrDisponibilidade.push('Comprar');
            if(planta.alugar === 1) arrDisponibilidade.push('Alugar');
            const disponibilidade = arrDisponibilidade.join(', ');
            rows.push({
                id: planta.id,
                data: [
                    imagemApresentacao,
                    planta?.projeto?.nome,
                    planta.tipo_planta,
                    planta.descricao,
                    disponibilidade,
                    `${planta.metros_quadrados}m²`,
                    planta.status === 1 ? 'Ativo' : 'Inativo'
                ],
                action: () => editPlanta(planta)
            })
        });

        setData({header, rows});
    }

    const storePlanta = () => {
        setLoading(true);

        api.post(`/plantas`, {
            projeto_id: plantaProjeto,
            tipo_planta_id: plantaTipo,
            metros_quadrados: plantaMetragem,
            descricao: plantaDescricao,
            detalhes: [plantaDetalhes1, plantaDetalhes2, plantaDetalhes3],
            alugar: plantaAlugar,
            comprar: plantaComprar,
            imagem_apresentacao: plantaImagemApresentacaoBase64,
            imagem_planta: plantaImagemPlantaBase64,
            status: plantaStatus,
            cv_id: plantaCvId
        }).then(res => {
            toast.success('Planta cadastrada com sucesso');
            setShowCreatePlanta(false);
            getPlantas();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const updatePlanta = () => {
        setLoading(true);

        api.put(`/plantas/${planta.id}`, {
            projeto_id: plantaProjeto,
            tipo_planta_id: plantaTipo,
            metros_quadrados: plantaMetragem,
            descricao: plantaDescricao,
            detalhes: [plantaDetalhes1, plantaDetalhes2, plantaDetalhes3],
            alugar: plantaAlugar,
            comprar: plantaComprar,
            imagem_apresentacao: plantaImagemApresentacaoBase64 === "" ? null : plantaImagemApresentacaoBase64,
            imagem_planta: plantaImagemPlantaBase64 === "" ? null : plantaImagemPlantaBase64,
            status: plantaStatus,
            cv_id: plantaCvId
        }).then(res => {
            toast.success('Planta atualizada com sucesso');
            setShowUpdatePlanta(false);
            getPlantas();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const setPlantaDetalhes = detalhes => {
        setPlantaDetalhes1(detalhes[0] ?? '');
        setPlantaDetalhes2(detalhes[1] ?? '');
        setPlantaDetalhes3(detalhes[2] ?? '');
    }

    const editPlanta = planta => {
        setPlanta(planta);
        setPlantaProjeto(planta.projeto.id);
        setPlantaTipo(planta.tipo_planta_id);
        setPlantaMetragem(planta.metros_quadrados);
        setPlantaAlugar(planta.alugar);
        setPlantaComprar(planta.comprar);
        setPlantaDescricao(planta.descricao);
        setPlantaDetalhes(planta.detalhes);
        setPlantaStatus(planta.status);
        setPlantaCvId(planta.cv_id);
        setPlantaImagemApresentacaoName(planta.imagem_apresentacao);
        setPlantaImagemPlantaName(planta.imagem_planta);
        setShowUpdatePlanta(true);
    }

    const clean = () => {
        setPlanta(null);
        setPlantaProjeto(null);
        setPlantaTipo(null);
        setPlantaMetragem(0);
        setPlantaAlugar(0);
        setPlantaComprar(0);
        setPlantaDescricao('');
        setPlantaDetalhes1('');
        setPlantaDetalhes2('');
        setPlantaDetalhes3('');
        setPlantaStatus(1);
        setPlantaCvId('');
        setPlantaImagemApresentacaoName('');
        setPlantaImagemApresentacaoBase64('');
        setPlantaImagemPlantaName('');
        setPlantaImagemPlantaBase64('');
    }

    const deletePlanta = () => {
        setLoading(true);

        api.delete(`/plantas/${plantaToDelete.id}`).then(res => {
            setPlantaToDelete(null);
            setShowUpdatePlanta(false);
            getPlantas();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    return (
        <>
            {showCreatePlanta &&
                <Modal
                    title={`Cadastrar planta`}
                    show={true}
                    close={() => setShowCreatePlanta(false)}
                    footer={
                        <Button 
                            type={`primary`}
                            size={`small`}
                            text={`Cadastrar`}
                            svg={`save-white`}
                            action={storePlanta}
                            loading={loading}
                        />
                    }
                >
                    <div className="section">
                        <div className="row">
                            <div className="col-4">
                                <InputSelect label={`Status`} options={statusOptions} value={plantaStatus} change={setPlantaStatus} />
                            </div>
                            <div className="col-4">
                                <Input
                                    label={`ID do CV`} 
                                    value={plantaCvId} 
                                    change={setPlantaCvId}
                                />
                            </div>
                            <div className="col-4">
                                <button
                                    className="btn-checkbox"
                                    onClick={() => setPlantaAlugar(plantaAlugar === 1 ? 0 : 1)}
                                >
                                    <InputCheckbox active={plantaAlugar === 1} />
                                    Alugar
                                </button>
                                <button
                                    className="btn-checkbox"
                                    onClick={() => setPlantaComprar(plantaComprar === 1 ? 0 : 1)}
                                >
                                    <InputCheckbox active={plantaComprar === 1} />
                                    Comprar
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <InputSelect label={`Projeto`} options={createOptions(projetos, 'nome', 'id')} value={plantaProjeto} change={setPlantaProjeto} />
                            </div>
                            <div className="col-4">
                                <InputSelect label={`Tipo`} options={createOptions(tipos, 'nome', 'id')} value={plantaTipo} change={setPlantaTipo} />
                            </div>
                            <div className="col-4">
                                <Input label={`Metragem`} value={plantaMetragem} change={setPlantaMetragem} mask={`convertToInt`} right={<span>m²</span>} />
                            </div>
                        </div>  
                        <div className="row">
                            <div className="col">
                                <InputTextarea label={`Descrição`} rows={3} value={plantaDescricao} change={setPlantaDescricao} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Input label={`Detalhes`} value={plantaDetalhes1} change={setPlantaDetalhes1} />
                                <Input label={`Detalhes`} value={plantaDetalhes2} change={setPlantaDetalhes2} />
                                <Input label={`Detalhes`} value={plantaDetalhes3} change={setPlantaDetalhes3} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p style={{ textAlign: 'center', fontSize: 14, color: "#747474"}}>Cadastre a planta para incluir imagens.</p>
                            </div>
                        </div>           
                    </div>
                </Modal>
            }
            {showUpdatePlanta &&
                <Modal
                    title={`Atualizar planta`}
                    show={true}
                    close={() => setShowUpdatePlanta(false)}
                    footer={
                        <>
                            <Button
                                type={`secondary`}
                                size={`small`}
                                text={`Excluir`}
                                svg={`delete-small`}
                                action={() => setPlantaToDelete(planta)}
                                loading={loading} 
                            />
                            <Button 
                                type={`primary`}
                                size={`small`}
                                text={`Salvar alterações`}
                                svg={`save-white`}
                                action={updatePlanta}
                                loading={loading}
                            />
                        </>
                    }
                >
                    <div className="section">
                        <div className="row">
                            <div className="col-4">
                                <InputSelect label={`Status`} options={statusOptions} value={plantaStatus} change={setPlantaStatus} />
                            </div>
                            <div className="col-4">
                                <Input
                                    label={`ID do CV`} 
                                    value={plantaCvId} 
                                    change={setPlantaCvId}
                                />
                            </div>
                            <div className="col-4">
                                <button
                                    className="btn-checkbox"
                                    onClick={() => setPlantaAlugar(plantaAlugar === 1 ? 0 : 1)}
                                >
                                    <InputCheckbox active={plantaAlugar === 1} />
                                    Alugar
                                </button>
                                <button
                                    className="btn-checkbox"
                                    onClick={() => setPlantaComprar(plantaComprar === 1 ? 0 : 1)}
                                >
                                    <InputCheckbox active={plantaComprar === 1} />
                                    Comprar
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <InputSelect label={`Projeto`} options={createOptions(projetos, 'nome', 'id')} value={plantaProjeto} change={setPlantaProjeto} />
                            </div>
                            <div className="col-4">
                                <InputSelect label={`Tipo`} options={createOptions(tipos, 'nome', 'id')} value={plantaTipo} change={setPlantaTipo} />
                            </div>
                            <div className="col-4">
                                <Input label={`Metragem`} value={plantaMetragem} change={setPlantaMetragem} mask={`convertToInt`} right={<span>m²</span>} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <InputTextarea label={`Descrição`} rows={3} value={plantaDescricao} change={setPlantaDescricao} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Input label={`Detalhes`} value={plantaDetalhes1} change={setPlantaDetalhes1} />
                                <Input label={`Detalhes`} value={plantaDetalhes2} change={setPlantaDetalhes2} />
                                <Input label={`Detalhes`} value={plantaDetalhes3} change={setPlantaDetalhes3} />
                            </div>
                        </div> 
                        {planta &&
                            <PlanImagesEdit planta={planta} /> 
                        }            
                    </div>
                </Modal>
            }
            <ModalDelete
                show={plantaToDelete !== null}
                action={deletePlanta}
                close={() => setPlantaToDelete(null)}
                loading={loading}
            />
            <PanelTemplate id="plant-list">
                <PageHeader
                    left={
                        <FilterSimple 
                            params={[
                                {name: 'Descrição', key: 'descricao', type: 'text' }
                            ]}
                            filter={setFilters}
                        />
                    }

                    right={
                        <>
                            <Button type={`primary`} size={`small`} text={`Cadastrar planta`} svg="plus-white" action={() => setShowCreatePlanta(true)}  />
                        </>
                    }
                />
                
                <Box className={`no-padding`}>
                    <TableSortable header={data.header} rows={data.rows} sort={sort} loading={loading} />
                </Box>
            </PanelTemplate>
        </>
    );
}

export default PlanList;